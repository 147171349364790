import _util from "./util";
import _es from "./es5";
var exports = {};

exports = function (NEXT_FILTER) {
  var util = _util;
  var getKeys = _es.keys;
  var tryCatch = util.tryCatch;
  var errorObj = util.errorObj;

  function catchFilter(instances, cb, promise) {
    return function (e) {
      var boundTo = promise._boundValue();

      predicateLoop: for (var i = 0; i < instances.length; ++i) {
        var item = instances[i];

        if (item === Error || item != null && item.prototype instanceof Error) {
          if (e instanceof item) {
            return tryCatch(cb).call(boundTo, e);
          }
        } else if (typeof item === "function") {
          var matchesPredicate = tryCatch(item).call(boundTo, e);

          if (matchesPredicate === errorObj) {
            return matchesPredicate;
          } else if (matchesPredicate) {
            return tryCatch(cb).call(boundTo, e);
          }
        } else if (util.isObject(e)) {
          var keys = getKeys(item);

          for (var j = 0; j < keys.length; ++j) {
            var key = keys[j];

            if (item[key] != e[key]) {
              continue predicateLoop;
            }
          }

          return tryCatch(cb).call(boundTo, e);
        }
      }

      return NEXT_FILTER;
    };
  }

  return catchFilter;
};

export default exports;