import _util from "./util";
var exports = {};

exports = function (Promise, INTERNAL, tryConvertToPromise, apiRejection, debug) {
  var util = _util;
  var tryCatch = util.tryCatch;

  Promise.method = function (fn) {
    if (typeof fn !== "function") {
      throw new Promise.TypeError("expecting a function but got " + util.classString(fn));
    }

    return function () {
      var ret = new Promise(INTERNAL);

      ret._captureStackTrace();

      ret._pushContext();

      var value = tryCatch(fn).apply(this, arguments);

      var promiseCreated = ret._popContext();

      debug.checkForgottenReturns(value, promiseCreated, "Promise.method", ret);

      ret._resolveFromSyncValue(value);

      return ret;
    };
  };

  Promise.attempt = Promise["try"] = function (fn) {
    if (typeof fn !== "function") {
      return apiRejection("expecting a function but got " + util.classString(fn));
    }

    var ret = new Promise(INTERNAL);

    ret._captureStackTrace();

    ret._pushContext();

    var value;

    if (arguments.length > 1) {
      debug.deprecated("calling Promise.try with more than 1 argument");
      var arg = arguments[1];
      var ctx = arguments[2];
      value = util.isArray(arg) ? tryCatch(fn).apply(ctx, arg) : tryCatch(fn).call(ctx, arg);
    } else {
      value = tryCatch(fn)();
    }

    var promiseCreated = ret._popContext();

    debug.checkForgottenReturns(value, promiseCreated, "Promise.try", ret);

    ret._resolveFromSyncValue(value);

    return ret;
  };

  Promise.prototype._resolveFromSyncValue = function (value) {
    if (value === util.errorObj) {
      this._rejectCallback(value.e, false);
    } else {
      this._resolveCallback(value, true);
    }
  };
};

export default exports;